import { ReloadOutlined } from '@ant-design/icons'
import { App as AntdApp, Button, ConfigProvider, Typography } from 'antd'
import { ThemeAppearance, ThemeMode, ThemeProvider, useTheme } from 'antd-style'
import 'bootstrap/dist/css/bootstrap.css'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'
import { AppProps } from 'next/app'
import Error from 'next/error'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { ReactNode } from 'react'
import { SSRProvider } from 'react-bootstrap'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { RecoilRoot } from 'recoil'
import { RecoilURLSyncJSONNext } from 'recoil-sync-next'
import BrandContextProvider from '../contexts/BrandContext'
import GlobalContextProvider from '../contexts/GlobalContext'
import NavContextProvider from '../contexts/NavContext'
import TalentContextProvider from '../contexts/TalentContext'
import TeamContextProvider from '../contexts/TeamContext'
import AbilityProvider from '../features/Auth/contexts/AbilityContext'
import AuthProvider from '../features/Auth/contexts/AuthContext'
import BillingProvider from '../features/Auth/contexts/BillingContext'
import PermissionProvider from '../features/Auth/contexts/PermissionContext'
import '../styles/globals.css'
const { Title, Paragraph } = Typography

const logrocketForBranch = (branch) => {
    switch (branch) {
        case 'alpha':
            return 'rspvga/alphaviscapai'
        case 'beta':
            return 'rspvga/betaviscapai'
        case 'main':
            return 'rspvga/appviscapai'
        default:
            return 'rspvga/devviscapai'
    }
}

if (typeof window !== 'undefined') {
    LogRocket.init(logrocketForBranch(process.env.GIT_BRANCH), { release: '2.2.0' })
    setupLogRocketReact(LogRocket)
}

const Noop = ({ children }) => <>{children}</>

const AppTheme = ({ children }: { children: ReactNode }) => {
    const theme = typeof localStorage !== 'undefined' ? localStorage.getItem('theme') : 'light'

    return (
        <ThemeProvider
            defaultThemeMode={theme as ThemeMode}
            defaultAppearance={theme as ThemeAppearance}
            onAppearanceChange={(a) => {
                localStorage.setItem('theme', a)
            }}
            theme={{
                cssVar: false,
                token: {
                    colorPrimary: '#00aaff',
                    fontFamily: 'Montserrat',
                },
            }}
        >
            <ConfigProvider>
                <AntdApp>{children}</AntdApp>
            </ConfigProvider>
        </ThemeProvider>
    )
}

const Recoil = ({ children }: { children: ReactNode }) => (
    <RecoilRoot>
        <RecoilURLSyncJSONNext location={{ part: 'queryParams' }}>{children}</RecoilURLSyncJSONNext>
    </RecoilRoot>
)

const Permissions = ({ children }: { children: ReactNode }) => (
    <PermissionProvider>
        <AbilityProvider>{children}</AbilityProvider>
    </PermissionProvider>
)

const UserContext = ({ children }: { children: ReactNode }) => (
    <AuthProvider>
        <TeamContextProvider>
            <BrandContextProvider>
                <Permissions>
                    <BillingProvider>
                        <TalentContextProvider>{children}</TalentContextProvider>
                    </BillingProvider>
                </Permissions>
            </BrandContextProvider>
        </TeamContextProvider>
    </AuthProvider>
)

const AuxContext = ({ children }: { children: ReactNode }) => (
    <GlobalContextProvider>
        <NavContextProvider>{children}</NavContextProvider>
    </GlobalContextProvider>
)

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
    const router = useRouter()
    const theme = useTheme()

    console.log(error)

    return (
        <AppTheme>
            <div
                className="d-flex justify-content-center align-items-center vh-100"
                style={{ backgroundColor: theme.colorBgElevated }}
            >
                <div className="d-flex justify-content-center align-items-center h-100 flex-column">
                    <Title level={1} className="mb-0">
                        Whoops!
                    </Title>
                    <Title level={4} className="mb-5">
                        Apologies, something went wrong
                    </Title>
                    <Paragraph className="mb-5" style={{ maxWidth: '30em', fontFamily: 'monospace' }}>
                        {error.message}
                    </Paragraph>
                    <Button
                        size="large"
                        type="primary"
                        onClick={() => {
                            resetErrorBoundary()
                            router.push('/admin').then(() => router.reload())
                        }}
                        icon={<ReloadOutlined />}
                    >
                        Reload App
                    </Button>
                </div>
            </div>
        </AppTheme>
    )
}

const Scripts = ({ isProd }) => (
    <>
        {isProd && (
            <>
                <Script
                    type="text/javascript"
                    src="https://app.termly.io/embed.min.js"
                    data-auto-block="on"
                    data-website-uuid="d70a1f26-ff55-463b-8472-93b054b49611"
                />
                {isProd && (
                    <Script
                        type="text/javascript"
                        id="hs-script-loader"
                        async
                        defer
                        src="//js.hs-scripts.com/20683323.js"
                    />
                )}
            </>
        )}
        <Script async src="https://www.googletagmanager.com/gtag/js?id=UA-151032275-1" />
    </>
)

type IAppProps = {
    errorCode?: number
} & AppProps

const MyApp = (props: IAppProps) => {
    const { Component, pageProps, errorCode } = props
    const isProd = ['main'].includes(process.env.GIT_BRANCH)

    if (errorCode) {
        return <Error statusCode={errorCode} />
    }

    //@ts-ignore
    const ComponentProvider = Component.provider || Noop

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="shortcut icon" href="/favicon.ico" />
            </Head>
            <SSRProvider>
                <UserContext>
                    <AuxContext>
                        <ComponentProvider>
                            <Recoil>
                                <Scripts isProd={isProd} />
                                <AppTheme>
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <Component {...pageProps} />
                                    </ErrorBoundary>
                                </AppTheme>
                            </Recoil>
                        </ComponentProvider>
                    </AuxContext>
                </UserContext>
            </SSRProvider>
        </>
    )
}

export default MyApp
