import { db } from '../../../firebase_config/firebase_config'
import {
    addDoc,
    arrayRemove,
    arrayUnion,
    collection,
    collectionGroup,
    CollectionReference,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    where,
    writeBatch,
} from 'firebase/firestore'
import { BrandData, BrandLibraryTalentData } from '../../../types'

export const addNewBrands = async (teamId: string, brands: BrandData[]): Promise<string[]> => {
    const batch = writeBatch(db)

    const ids = brands.map((b) => {
        const docRef = doc(collection(db, 'brands'))
        batch.set(docRef, { ...b, id: docRef.id })
        return docRef.id
    })

    try {
        batch.commit()
        return ids
    } catch (error: any) {
        throw new Error(error.message, error.stack)
    }

    // Cloud function adds brandId to team
}

export const getAllBrands = async (): Promise<BrandData[]> => {
    let res: BrandData[] = []

    const q = collection(db, 'brands')

    try {
        const snapshot = await getDocs(q)
        snapshot.forEach((d) => res.push({ id: d.id, ...(d.data() as BrandData) }))
    } catch (error) {
        console.log(error.message)
    }

    return res
}

export const getUserBrands = async (ids: string[]): Promise<BrandData[]> => {
    const queryArray = ids.map((id) => query(collection(db, `brands`), where('id', '==', id)))

    const promises = queryArray.map(async (query) => await getDocs(query))
    const results = await Promise.all(promises)

    return results.map((snap) => snap.docs.map((d) => ({ ...(d.data() as BrandData), id: d.id }))).flat()
}

export const getTalentBrands = async (talentId: string): Promise<BrandData[]> => {
    const queryArr = query(collectionGroup(db, 'talentSuggestions'), where('id', '==', talentId))

    const snap = await getDocs(queryArr)

    const brandIds = snap.docs.map((d) => d.data().brand)

    return getUserBrands(brandIds)
}

export const getBrand = async (brandId: string): Promise<BrandData> => {
    let res: BrandData

    const q = doc(db, 'brands', brandId)

    try {
        const snapshot = await getDoc(q)
        res = { id: snapshot.id, ...(snapshot.data() as BrandData) }
    } catch (error) {
        console.log(error.message)
    }

    return res
}

export const saveBrandFirestore = async (brand: Partial<BrandData>) => {
    const ref = doc(db, 'brands', brand.id)

    try {
        await updateDoc(ref, brand)
    } catch (error) {
        console.log(error)
    }
}

export const getBrandFirestore = async (brandId) => {
    const docRef = doc(db, 'brands/', brandId)
    try {
        const res = await getDoc(docRef)
        return { ...res.data(), id: res.id } as BrandData
    } catch (err) {
        console.log(err)
    }
}

export const brandAssignUsers = async (brandId, userIds) => {
    try {
        await updateDoc(doc(db, 'brands', brandId), {
            assignedUsers: arrayUnion(...userIds.map((uid) => ({ id: uid }))),
            accessRequests: arrayRemove(...userIds.map((uid) => ({ id: uid }))),
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const brandRevokeUsers = async (brandId, userIds) => {
    try {
        await updateDoc(doc(db, 'brands', brandId), {
            assignedUsers: arrayRemove(...userIds.map((uid) => ({ id: uid }))),
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const brandRequestAccess = async (brandId, userId) => {
    try {
        await updateDoc(doc(db, 'brands', brandId), { accessRequests: arrayUnion({ id: userId }) })
    } catch (error) {
        throw new Error(error)
    }
}

export const getBrandActors = async (data: {
    brandId: string
    actorIds: string[]
    fetchAll?: boolean
}): Promise<BrandLibraryTalentData[]> => {
    const { brandId, actorIds, fetchAll } = data

    const brandActorsCollectionRef: CollectionReference<BrandLibraryTalentData> = collection(
        db,
        'brands',
        brandId,
        'talentSuggestions'
    ) as CollectionReference<BrandLibraryTalentData>

    let actorsQuery
    if (fetchAll) {
        actorsQuery = brandActorsCollectionRef
    } else {
        actorsQuery = query(brandActorsCollectionRef, where('id', 'in', actorIds))
    }

    const querySnapshot = await getDocs(actorsQuery)

    return querySnapshot.docs.map((doc) => doc.data() as BrandLibraryTalentData)
}

export const checkBrandNameOriginality = async (teamId: string, name: string) => {
    const targetTeam = await getDoc(doc(db, 'teams', teamId))

    const targetTeamBrands = await Promise.all(
        targetTeam.data().brands.map(async (b) => {
            return await getDoc(doc(db, 'brands', b.id))
        })
    )

    return !targetTeamBrands.find((b) => b.data()?.name === name)
}

export const addTeamBrand = async (
    teamId: string,
    brandData: {
        name: string
        description: string
    }
) => {
    try {
        const brandRef = await addDoc(collection(db, 'brands'), {})

        await updateDoc(brandRef, {
            id: brandRef.id,
            name: brandData.name,
            description: brandData.description,
            namingConvention: {
                enforce: false,
                fields: [],
                separators: [],
                annexes: [],
            },
            teams: [
                {
                    id: teamId,
                    teamRole: 'owners',
                },
            ],
        })

        const teamRef = doc(db, 'teams', teamId)

        await updateDoc(teamRef, {
            brands: arrayUnion({
                id: brandRef.id,
                teamRole: 'owners',
            }),
        })
    } catch (e) {
        console.log(e)
        return e
    }
}

export const firestoreCheckBrandName = async (name) => {
    const brandsRef = collection(db, 'brands')
    const q = query(brandsRef, where('name', '==', name))

    const snap = await getDocs(q)

    return snap.size === 0
}
