import { useCollection, useGetDoc } from '@tatsuokaniwa/swr-firestore'
import { FC, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useBrandContext } from '../../../contexts/BrandContext'
import { useTeamContext } from '../../../contexts/TeamContext'
import useProductAdditionalStorage from '../../../hooks/useProductAdditionalStorage'
import useSubscriptionProducts from '../../../hooks/useSubscriptionProducts'
import { useAuth } from './AuthContext'

interface IBillingContext {
    subscriptions: any[]
    activeSubscription: any | null
    lastSubscription: any | null
    subscriptionStatus: string | null
    subscriptionPlan: string | null
    price: {
        itemId: string | null
        priceId: string | null
    }
    activeCreatives: number
    activeSubscriptionProduct: any
    additionalStorage: number
    storageLimitBytes: number
    storageLimitGb: number
    schedule: {
        product: string
        activeCreatives: number
        newAddonStorage: number
        id: string
    } | null
    talentLimit: number
    brandBillingInfo: {
        isBrandSubscriptionActive: boolean
        activeCreatives: number
        storageLimitBytes: number
        storageLimitGb: number
        talentLimit: number
        periodStart: Date
    }
    subscriptionProducts: any[]
}

export const BillingContext = createContext<IBillingContext>(null!)

// export const Can = createContextualCan(BillingContext.Consumer)

export const useBilling = () => useContext(BillingContext)

const BillingProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { userDB } = useAuth()
    const { additionalStoragePrice } = useProductAdditionalStorage()
    const { subscriptionProducts } = useSubscriptionProducts()
    const { currentTeamOwnerId, isViscapTeam } = useTeamContext()
    const { ownerTeamId: brandOwnerTeamId, isViscapBrand, brandOwnerId } = useBrandContext()

    const [activeSubscription, setActiveSubscription] = useState<any>(null)
    const [brandOwnerActiveSubscription, setBrandOwnerActiveSubscription] = useState<any>(null)
    const [lastSubscription, setLastSubscription] = useState<any>(null)

    const { data: subscriptions = null } = useCollection<any>(
        userDB && currentTeamOwnerId
            ? {
                  path: `users/${currentTeamOwnerId}/subscriptions`,
                  parseDates: ['created', 'current_period_end', 'current_period_start', 'canceled_at'],
              }
            : null
    )

    const { data: brandOwnerSubscriptions = null } = useCollection<any>(
        userDB && brandOwnerId
            ? {
                  path: `users/${brandOwnerId}/subscriptions`,
                  parseDates: ['created', 'current_period_end', 'current_period_start', 'canceled_at'],
              }
            : null
    )

    const { data: schedules = null } = useCollection<any>(
        userDB && currentTeamOwnerId && activeSubscription
            ? {
                  path: `users/${currentTeamOwnerId}/subscriptions/${activeSubscription.id}/schedules`,
              }
            : null
    )

    useEffect(() => {
        if (subscriptions) {
            const activeSub = subscriptions.find((sub) => sub.status === 'active')
            setActiveSubscription(activeSub || null)

            if (!activeSub) {
                const lastSub = [...subscriptions].reverse()[0]
                setLastSubscription(lastSub || null)
            }
        }
    }, [subscriptions])

    useEffect(() => {
        if (brandOwnerSubscriptions) {
            const activeSub = brandOwnerSubscriptions.find((sub) => sub.status === 'active')
            setBrandOwnerActiveSubscription(activeSub || { status: 'inactive' })
        }
    }, [brandOwnerSubscriptions])

    const subscriptionStatus = useMemo(() => {
        if (activeSubscription) {
            return activeSubscription.status
        }

        if (lastSubscription) {
            return lastSubscription.status
        }

        return null
    }, [activeSubscription, lastSubscription])

    const brandOwnerSubscriptionStatus = useMemo(() => {
        if (brandOwnerActiveSubscription) {
            return brandOwnerActiveSubscription.status
        }

        return null
    }, [brandOwnerActiveSubscription])

    const additionalStorage = useMemo(() => {
        const sub = activeSubscription //?? lastSubscription
        if (!sub || !additionalStoragePrice || !sub.prices) {
            return 0
        }

        if (sub.prices.length === 1) {
            return 0
        }

        const asItem = sub.items.find((it) => it.price.product.metadata?.nickname === 'additionalStorage')

        // return parseInt(asItem.quantity)
        return parseInt(asItem.quantity) / 100
    }, [activeSubscription, additionalStoragePrice])

    const price = useMemo(() => {
        const sub = activeSubscription //?? lastSubscription
        if (!sub || !sub.prices) {
            return 0
        }

        if (sub.prices.length === 0) {
            return 0
        }

        // console.log('sub', sub)

        const asItem = sub.items.find((it) =>
            ['basic', 'full', 'enterprise'].map((p) => it.price.nickname?.includes(p)).includes(true)
        )

        if (!asItem) {
            return 0
        }

        return asItem.price
    }, [activeSubscription])

    const priceItemId = useMemo(() => {
        const sub = activeSubscription //?? lastSubscription
        if (!sub || !sub.prices) {
            return 0
        }

        if (sub.prices.length === 0) {
            return 0
        }

        // console.log('sub', sub)

        const asItem = sub.items.find((it) =>
            ['basic', 'full', 'enterprise'].map((p) => it.price.nickname?.includes(p)).includes(true)
        )

        if (!asItem) {
            return 0
        }

        return asItem.id
    }, [activeSubscription])

    const brandOwnerPrice = useMemo(() => {
        const sub = brandOwnerActiveSubscription
        if (!sub || !sub.prices) {
            return 0
        }

        if (sub.prices.length === 0) {
            return 0
        }

        // console.log('sub', sub)

        const asItem = sub.items.find((it) =>
            ['basic', 'full', 'enterprise'].map((p) => it.price.nickname?.includes(p)).includes(true)
        )

        if (!asItem) {
            return 0
        }

        return asItem.price
    }, [brandOwnerActiveSubscription])

    const product = useMemo(() => {
        return price.product
    }, [price])

    const brandOwnerProduct = useMemo(() => {
        return brandOwnerPrice.product
    }, [brandOwnerPrice])

    const activeCreatives = useMemo(() => {
        if (!price) {
            return 0
        }

        // console.log('price', price)

        if (['basic', 'full', 'enterprise'].map((p) => price.nickname.includes(p)).includes(true)) {
            const [_, creatives] = price.nickname.split('_')
            return parseInt(creatives)
        }

        return 0
    }, [price])

    const brandOwnerActiveCreatives = useMemo(() => {
        if (!brandOwnerPrice) {
            return 0
        }

        // console.log('price', price)

        if (['basic', 'full', 'enterprise'].map((p) => brandOwnerPrice.nickname.includes(p)).includes(true)) {
            const [_, creatives] = brandOwnerPrice.nickname.split('_')
            return parseInt(creatives)
        }

        return 0
    }, [brandOwnerPrice])

    const storageGb: number = useMemo(() => {
        // console.log('storageGb product', product)

        if (isViscapTeam) {
            return Infinity
        }

        if (!product || !product?.metadata?.storage) {
            return 0
        }

        if (price && price.metadata?.storage) {
            return parseInt(price.metadata.storage)
        }

        // return parseInt(product.metadata.storage)
        return parseInt(product.metadata.storage) / 100
    }, [product, price, isViscapTeam])

    const brandOwnerStorageGb: number = useMemo(() => {
        // console.log('storageGb brandOwnerProduct', brandOwnerProduct)

        if (isViscapBrand) {
            return Infinity
        }

        if (!brandOwnerProduct || !brandOwnerProduct?.metadata?.storage) {
            return 0
        }

        if (brandOwnerPrice && brandOwnerPrice.metadata?.storage) {
            return parseInt(brandOwnerPrice.metadata.storage)
        }

        // return parseInt(brandOwnerProduct.metadata.storage)
        return parseInt(brandOwnerProduct.metadata.storage) / 100
    }, [brandOwnerProduct, brandOwnerPrice, isViscapBrand])

    const talentLimit: number = useMemo(() => {
        // console.log('talentLimit product', product)

        if (isViscapTeam) {
            return Infinity
        }

        if (!product || !product?.metadata?.actors) {
            return 0
        }

        if (price && price.metadata?.actors) {
            return parseInt(price.metadata.actors)
        }

        if (product.metadata.actors === 'unlimited') {
            return Infinity
        }

        return parseInt(product.metadata.actors)
    }, [product, price, isViscapTeam])

    const brandOwnerTalentLimit: number = useMemo(() => {
        // console.log('talentLimit product', brandOwnerProduct)

        if (isViscapBrand) {
            return Infinity
        }

        if (!brandOwnerProduct || !brandOwnerProduct?.metadata?.actors) {
            return 0
        }

        if (brandOwnerPrice && brandOwnerPrice.metadata?.actors) {
            return parseInt(brandOwnerPrice.metadata.actors)
        }

        if (brandOwnerProduct.metadata.actors === 'unlimited') {
            return Infinity
        }

        return parseInt(brandOwnerProduct.metadata.actors)
    }, [brandOwnerProduct, brandOwnerPrice, isViscapBrand])

    const isBrandSubscriptionActive = useMemo(() => {
        if (isViscapBrand) {
            return true
        }

        if (brandOwnerSubscriptionStatus === null) {
            return null
        }

        if (!brandOwnerActiveSubscription) {
            return false
        }

        return brandOwnerSubscriptionStatus === 'active'
    }, [brandOwnerActiveSubscription, brandOwnerSubscriptionStatus, isViscapBrand])

    const additionalStorageBrand = useMemo(() => {
        const sub = brandOwnerActiveSubscription
        if (!sub || !additionalStoragePrice || !sub.prices) {
            return 0
        }

        if (sub.prices.length === 1) {
            return 0
        }

        const asItem = sub.items.find((it) => it.price.product.metadata?.nickname === 'additionalStorage')

        // return parseInt(asItem.quantity)
        return parseInt(asItem.quantity) / 100
    }, [brandOwnerActiveSubscription, additionalStoragePrice])

    const schedule = useMemo(() => {
        if (!schedules || schedules.length === 0 || !additionalStoragePrice || subscriptionProducts.length === 0) {
            return null
        }

        const now = new Date()
        const activeSchedule = schedules.find((sch) => sch.phases[1].start_date.toDate() > now)
        if (!activeSchedule) {
            return null
        }

        const newItems = activeSchedule.phases[1].items
        const newSubPrice = newItems[0].price
        let newAddonStorage = 0
        if (newItems.length === 2 && newItems[1].price === additionalStoragePrice.id) {
            newAddonStorage = Number(newItems[1].quantity)
        }

        let prodName = ''
        let activeCreatives = 0

        for (const prod of subscriptionProducts) {
            for (const [key, value] of Object.entries(prod.priceIds)) {
                if (value === newSubPrice) {
                    prodName = prod.name
                    activeCreatives = Number(key.split('_')[1])
                    break
                }
            }

            if (prodName) {
                break
            }
        }

        return {
            id: activeSchedule.id,
            product: prodName,
            activeCreatives,
            newAddonStorage,
        }
    }, [schedules, additionalStoragePrice, subscriptionProducts])

    const storageLimitGb = useMemo(() => {
        return storageGb + additionalStorage * 100
    }, [storageGb, additionalStorage])

    const brandStorageLimitGb = useMemo(() => {
        return brandOwnerStorageGb + additionalStorageBrand * 100
    }, [brandOwnerStorageGb, additionalStorageBrand])

    const storageLimitBytes = useMemo(() => {
        return storageLimitGb * 1024 ** 3
    }, [storageLimitGb])

    const brandStorageLimitBytes = useMemo(() => {
        return brandStorageLimitGb * 1024 ** 3
    }, [brandStorageLimitGb])

    const value = {
        subscriptions,
        activeSubscription,
        lastSubscription,
        subscriptionStatus,
        subscriptionPlan: product?.name || '',
        price: {
            itemId: priceItemId,
            priceId: price?.id || null,
        },
        activeCreatives,
        activeSubscriptionProduct: product,
        additionalStorage,
        storageLimitBytes,
        storageLimitGb,
        schedule,
        talentLimit,
        brandBillingInfo: {
            isBrandSubscriptionActive,
            activeCreatives: brandOwnerActiveCreatives,
            storageLimitBytes: brandStorageLimitBytes,
            storageLimitGb: brandStorageLimitGb,
            talentLimit: brandOwnerTalentLimit,
            periodStart: brandOwnerActiveSubscription?.current_period_start,
        },
        subscriptionProducts,
    }

    // console.log('BillingContext', value)

    return <BillingContext.Provider value={value}>{children}</BillingContext.Provider>
}

export default BillingProvider
