import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { PermissionTemplate, Permissions } from '../../../types'
import { useBrandContext } from '../../../contexts/BrandContext'
import { useAuth } from './AuthContext'
import { useTeamContext } from '../../../contexts/TeamContext'
import { useCollection, useDoc } from '@tatsuokaniwa/swr-firestore'
import defaultPermissions from '../../../data/permissions/default.json'

interface IPermissionData {
    permissions: Permissions | null
    permissionTemplates: PermissionTemplate[] | null
    brandPermissions: Permissions | null
    userPermissions: Permissions | null
}

export const PermissionContext = createContext<IPermissionData>(null!)

export const usePermissions = () => useContext(PermissionContext)

const PermissionProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { userDB } = useAuth()
    const { brand } = useBrandContext()
    const { currentTeam } = useTeamContext()

    const [permissions, setPermissions] = useState<Permissions | null>(null)

    const { data: userPermissions = defaultPermissions as Permissions } = useDoc<Permissions>(
        userDB && currentTeam ? { path: `permissionsUser/${currentTeam.id}/users/${userDB.uid}` } : null
    )
    const { data: brandPermissions = defaultPermissions as Permissions } = useDoc<Permissions>(
        userDB && brand && currentTeam ? { path: `permissionsBrand/${brand.id}/teams/${currentTeam.id}` } : null
    )

    const { data: permissionTemplates } = useCollection<PermissionTemplate>(
        userDB && currentTeam ? { path: `teams/${currentTeam.id}/templates` } : null
    )

    const minPermissions = (userPermissions: Permissions, brandPermissions: Permissions) => {
        type aOType = 'all' | 'own'

        type aAType = 'all' | 'assigned'
        const allOrAssigned = (left: aAType, right: aAType): aAType => {
            return left === right ? left : 'assigned'
        }

        type aOAType = 'all' | 'own' | 'assigned'

        const u = userPermissions
        const b = brandPermissions

        // console.log('PermissionProvider::userPermissions', userPermissions)
        // console.log('PermissionProvider::brandPermissions', brandPermissions)

        return {
            brand: {
                info: {
                    read: u.brand.info.read && b.brand.info.read,
                    update: u.brand.info.update && b.brand.info.update,
                },
                creatives: {
                    create: u.brand.creatives.create && b.brand.creatives.create,
                    delete: u.brand.creatives.delete && b.brand.creatives.delete,
                    read: allOrAssigned(u.brand.creatives.read, b.brand.creatives.read),
                    update: allOrAssigned(u.brand.creatives.update, b.brand.creatives.update),
                    approve: u.brand.creatives.approve && b.brand.creatives.approve,
                },
                casting: {
                    approve: u.brand.casting.approve && b.brand.casting.approve,
                    read: u.brand.casting.read && b.brand.casting.read,
                    submit: u.brand.casting.submit && b.brand.casting.submit,
                },
                products: {
                    create: u.brand.products.create && b.brand.products.create,
                    delete: u.brand.products.delete && b.brand.products.delete,
                    read: u.brand.products.read && b.brand.products.read,
                    update: u.brand.products.update && b.brand.products.update,
                },
                gwtb: {
                    create: u.brand.gwtb.create && b.brand.gwtb.create,
                    delete: u.brand.gwtb.delete && b.brand.gwtb.delete,
                    read: u.brand.gwtb.read && b.brand.gwtb.read,
                    update: u.brand.gwtb.update && b.brand.gwtb.update,
                },
                teams: {
                    create: u.brand.teams.create && b.brand.teams.create,
                    delete: u.brand.teams.delete && b.brand.teams.delete,
                    update: u.brand.teams.update && b.brand.teams.update,
                },
                ideation: {
                    create: u.brand.ideation.create && b.brand.ideation.create,
                    delete: u.brand.ideation.delete && b.brand.ideation.delete,
                    read: allOrAssigned(u.brand.ideation.read, b.brand.ideation.read),
                    update: allOrAssigned(u.brand.ideation.update, b.brand.ideation.update),
                    submit: u.brand.ideation.submit && b.brand.ideation.submit,
                    approve: u.brand.ideation.approve && b.brand.ideation.approve,
                },
                media: {
                    create: u.brand.media.create && b.brand.media.create,
                    delete: u.brand.media.delete && b.brand.media.delete,
                    read: u.brand.media.read && b.brand.media.read,
                    update: u.brand.media.update && b.brand.media.update,
                }
            },
            team: {
                info: {
                    read: u.team.info.read && b.team.info.read,
                    update: u.team.info.update && b.team.info.update,
                },
                billing: {
                    manage: u.team.billing.manage && b.team.billing.manage,
                },
                members: {
                    create: u.team.members.create && b.team.members.create,
                    delete: u.team.members.delete && b.team.members.delete,
                    update: u.team.members.update && b.team.members.update,
                },
                brands: {
                    create: u.team.brands.create && b.team.brands.create,
                    delete: u.team.brands.delete && b.team.brands.delete,
                    read: allOrAssigned(u.team.brands.read, b.team.brands.read),
                },
                talent: {
                    approve: u.team.talent.approve && b.team.talent.approve,
                    read: u.team.talent.read && b.team.talent.read,
                    submit: u.team.talent.submit && b.team.talent.submit,
                },
                frameworks: {
                    create: u.team.frameworks.create && b.team.frameworks.create,
                    delete: u.team.frameworks.delete && b.team.frameworks.delete,
                    read: u.team.frameworks.read && b.team.frameworks.read,
                    update: u.team.frameworks.update && b.team.frameworks.update,
                }
            }
        }
    }

    useEffect(() => {
        if (userPermissions && brandPermissions) {
            setPermissions(minPermissions(userPermissions, brandPermissions))
        }
    }, [userPermissions, brandPermissions])

    const value = {
        permissions,
        permissionTemplates,
        brandPermissions,
        userPermissions,
    }

    return <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>
}

export default PermissionProvider
