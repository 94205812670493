import { useState, useEffect } from 'react'
import { FieldPath, collection, getDocs, query, where } from '@firebase/firestore'
import { db } from '../firebase_config/firebase_config'
import { useAuth } from '../features/Auth/contexts/AuthContext'

const useProductAdditionalStorage = () => {
    const { userDB } = useAuth()
    const [price, setPrice] = useState(null)

    useEffect(() => {
        const getPrice = async () => {
            const fieldPath = new FieldPath('metadata', 'nickname')
            const q = query(collection(db, 'stripe-products'), where(fieldPath, '==', 'additionalStorage'))
            let res = []
            try {
                const snap = await getDocs(q)
                snap.forEach((d) => res.push({ id: d.id, ref: d.ref, ...d.data() }))
            } catch (error) {
                return
            }

            const product = res[0]

            const pq = query(collection(product.ref, 'prices'), where('active', '==', true))
            let pres = []

            try {
                const psnap = await getDocs(pq)
                psnap.forEach((d) => pres.push({ id: d.id, ...d.data() }))
            } catch (error) {
                return
            }

            const price = pres[0]
            setPrice(price)
        }

        if (userDB && !price) {
            getPrice()
        }
    }, [userDB, price])

    return {
        additionalStoragePrice: price,
    }
}

export default useProductAdditionalStorage
